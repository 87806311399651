.action-button {
    background: none;
    border-radius: 15px;
    border: 2px solid var(--primary-color);
    font-weight: bold;
    padding: 5px 10px;
    cursor: pointer;
    outline: 0;
    width: 100%;
    transition: background-color .15s, color .15s;
    color: var(--primary-color);
}

.action-button:disabled {
    cursor: default;
}

.action-button.selected {
    background: var(--primary-color);
    color: white;
}


